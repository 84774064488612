import React from "react";
import { graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import Layout from "../components/layout";
import { buildBlocks } from "../services/builder";
import { CoreFields } from "./fragment"; // eslint-disable-line
import Breadcrumb from "../components/Breadcrumb";

export default function CaseStudy(props) {
  props.pageContext.pageTitle = props.data.wpCaseStudy.title;
  props.pageContext.date = props.data.wpCaseStudy.date;
  const blocks = buildBlocks(
    props.data.wpCaseStudy.blocks,
    {},
    props.pageContext
  );

  return (
    <Layout
      meta={{
        ...props?.data?.wpCaseStudy.Meta,
        canonical: props?.data?.wpCaseStudy.uri,
      }}
      path={props?.pageContext?.pagePath}
      cta={props?.data?.wpCaseStudy.PageCTA}
      themeOptions={props?.data?.wp.themeOptions}
    >
      <Breadcrumb
        type="case-study"
        current={{
          title: props?.data?.wpCaseStudy.title,
          slug: props?.data?.wpCaseStudy.slug,
          uri: props?.data?.wpCaseStudy.uri,
        }}
      />
      {blocks}
    </Layout>
  );
}

export const query = graphql`
  query($id: String) {
    wpCaseStudy(id: { eq: $id }, status: { eq: "publish" }) {
      id
      slug
      title
      uri
      content
      date(formatString: "DD MMMM YYYY")
      featuredImage {
        node {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      ... on WpBlockEditorContentNode {
        blocks {
          ...CoreBlock
          ...CoreFields
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          brochure {
            localFile {
              publicURL
            }
            title
            sourceUrl
            id
          }
          specificationBrochure {
            localFile {
              publicURL
            }
            title
            sourceUrl
            id
          }
        }
      }
    }
  }
`;
